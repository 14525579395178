import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";
const TeamAreaGroup = () => {
  return (
    <>
      {/* ================= TeamAreaGroup Start =================*/}
      <div className='team-area bg-[#183064] bg-relative pd-top-120 pd-bottom-90'>
        <div className='container '>
          <div className='row'>
            <div className='col-lg-6 col-md-6'>
           
              <div className='single-team-inner flex justify-center bg-white text-center'>    
                         
                <div className='details w-full '>
                  <img className="w-20" src="assets/img/case-study/ard-logo.png"/>               
                  <p className="text-black font-bold text-start mt-4 text-sm">ARD Pharmacy is a New York-based compounding pharmacy dedicated to providing personalized medications tailored to individual needs.</p>
                  <div className=" flex mt-4 gap-1 ">
                    <span className="text-gray-700 text-sm font-bold">Industry</span>
                    <span className="text-gray-700  text-sm font-bold"> | </span>
                    <span className="text-[#E47D1F] text-sm">Pharmacuticals</span>
                  </div>
                </div>
                <div className='details flex justify-center items-center w-full  '>
                <img  src="assets/img/case-study/phar.jpg"/>

                </div>
              </div>
            </div>
            <div className='col-lg-6 col-md-6'>
           
           <div className='single-team-inner flex justify-center bg-white text-center'>    
                      
             <div className='details w-full '>
               <img className="w-20" src="assets/img/case-study/audit-logo.png"/>               
               <p className="text-black font-bold text-start mt-4 text-sm">Pharmacy Audit Pro is a U.S.-based software company specializing in Excel-based comparison and audit reporting for the pharmacy industry.</p>
               <div className=" flex mt-4 gap-1 ">
                 <span className="text-gray-700 text-sm font-bold">Industry</span>
                 <span className="text-gray-700  text-sm font-bold"> | </span>
                 <span className="text-[#E47D1F] text-sm">Excel Reports</span>
               </div>
             </div>
             <div className='details flex justify-center items-center w-full  '>
             <img  src="assets/img/case-study/excel.jpg"/>

             </div>
           </div>
         </div>




            <div className='col-lg-6 col-md-6'>
           
           <div className='single-team-inner flex justify-center bg-white text-center'>    
                      
             <div className='details w-full '>
               <img className="w-20" src="assets/img/case-study/iv-logo.png"/>               
               <p className="text-black font-bold text-start mt-4 text-sm">IV Cure Pharmacy is a New York-based compounding pharmacy specializing in customized medications to meet individual patient needs.</p>
               <div className=" flex mt-4 gap-1 ">
                 <span className="text-gray-700 text-sm font-bold">Industry</span>
                 <span className="text-gray-700  text-sm font-bold"> | </span>
                 <span className="text-[#E47D1F] text-sm">Pharmacuticals</span>
               </div>
             </div>
             <div className='details flex justify-center items-center w-full  '>
             <img  src="assets/img/case-study/medi.jpg"/>

             </div>
           </div>
         </div>
         <div className='col-lg-6 col-md-6'>
           
           <div className='single-team-inner flex justify-center bg-white text-center'>    
                      
             <div className='details w-full '>
               <img className="w-20" src="assets/img/case-study/rana-logo.png"/>               
               <p className="text-black font-bold text-start mt-4 text-sm">Ranasons Builders is a leading construction and building company based in Lahore, Pakistan. the company ensures innovative solutions tailored to clients' needs.</p>
               <div className=" flex mt-4 gap-1 ">
                 <span className="text-gray-700 text-sm font-bold">Industry</span>
                 <span className="text-gray-700  text-sm font-bold"> | </span>
                 <span className="text-[#E47D1F] text-sm">Construction</span>
               </div>
             </div>
             <div className='details flex justify-center items-center w-full  '>
             <img  src="assets/img/case-study/const.jpg"/>

             </div>
           </div>
         </div>
         <div className='col-lg-6 col-md-6'>
           
           <div className='single-team-inner flex justify-center bg-white text-center'>    
                      
             <div className='details w-full '>
               <img className="w-20" src="assets/img/case-study/brain-logo.png"/>               
               <p className="text-black font-bold text-start mt-4 text-sm">BrainStorm Digital Marketing Agency is a Lahore-based firm specializing in innovative and data-driven marketing solutions.</p>
               <div className=" flex mt-4 gap-1 ">
                 <span className="text-gray-700 text-sm font-bold">Industry</span>
                 <span className="text-gray-700  text-sm font-bold"> | </span>
                 <span className="text-[#E47D1F] text-sm">Marketing</span>
               </div>
             </div>
             <div className='details flex justify-center items-center w-full  '>
             <img  src="assets/img/case-study/maketing.jpg"/>

             </div>
           </div>
         </div>
         <div className='col-lg-6 col-md-6'>
           
           <div className='single-team-inner flex justify-center bg-white text-center'>    
                      
             <div className='details w-full '>
               <img className="w-20" src="assets/img/case-study/sams-logo.png"/>               
               <p className="text-black font-bold text-start mt-4 text-sm">Sams Healthcare is a leading medicine manufacturing company based in Lahore, Pakistan, committed to producing high-quality pharmaceutical products.</p>
               <div className=" flex mt-4 gap-1 ">
                 <span className="text-gray-700 text-sm font-bold">Industry</span>
                 <span className="text-gray-700  text-sm font-bold"> | </span>
                 <span className="text-[#E47D1F] text-sm">Pharmacuticals</span>
               </div>
             </div>
             <div className='details flex justify-center items-center w-full  '>
             <img  src="assets/img/case-study/pharma.jpg"/>

             </div>
           </div>
         </div>
       
          </div>
        </div>
      </div>
      {/* ================= TeamAreaGroup End =================*/}
    </>
  );
};

export default TeamAreaGroup;
