import React from "react";
import { FaPlus } from "react-icons/fa";

const ProjectAreaOne = () => {
  return (
    <div className='project-section pd-top-120'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='section-title text-center'>
              <h6 className='sub-title'>Projects</h6>
              <h2 className='title'>
              Our Work <span>Speaks</span>   for Itself
              </h2>
            </div>
          </div>
        </div>
      
        <div className='tab-content' id='nav-tabContent'>
          <div
            className='tab-pane fade show active'
            id='nav-tabs1'
            role='tabpanel'
            aria-labelledby='nav-tabs1-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/ivcure.jpg' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      IV Cure Pharmacy New York.
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/ard.jpg' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                    Ard Pharmacy Queens, New York.
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/mediprime.jpg' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                    Medi Prime Ozon Park, New York.
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/sams.jpg' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                    Sams Health Care Lahore, Pakistan.
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/ranasons.jpg' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      Rana sons Developers and Builders Lahore Pakistan.
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/landmark.jpg' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      Land Mark Enegery and Solar systems.
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/audit.jpg' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      Pharmacy Audit Pro U.S Based Software for Excel comparison.
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/branstorm.jpg' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      Brain Storm Marketing Agency
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/samsdash.jpg' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      Admin Dashboard to manage the Products, users, stock management, and transfer and more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs2'
            role='tabpanel'
            aria-labelledby='nav-tabs2-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/sams.jpg' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs3'
            role='tabpanel'
            aria-labelledby='nav-tabs3-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/1.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs4'
            role='tabpanel'
            aria-labelledby='nav-tabs4-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/1.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className='tab-pane fade'
            id='nav-tabs5'
            role='tabpanel'
            aria-labelledby='nav-tabs5-tab'
          >
            <div className='row'>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/1.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/5.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/6.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/2.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/7.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/3.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/4.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
                <div className='single-project-inner'>
                  <img src='assets/img/gallery/8.png' alt='img' />
                  <div className='details'>
                    <span>
                      <FaPlus />
                    </span>
                    <a className='read-more-text' href='#'>
                      MORE PROJECT
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectAreaOne;
