import React, { useState } from "react";
import axios from "axios";

const ContactAreaOne = () => {


  const [formData, setFormData] = useState({
    name: "",
    email: "",
    contact: "",
    subject: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  // Handle input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const url = window.location.hostname === "localhost" ? "http://localhost:3000" : "https://api.samhealthcarepk.com"

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage("");

    try {
      const response = await axios.post(`${url}/api/quotes/add`, formData);
      setResponseMessage("Information submitted successfully, We will Contact you shortly!");
      setFormData({ name: "", email: "", contact: "", subject: "", message: "" }); // Reset form
    } catch (error) {
      setResponseMessage("Failed to submit quote. Please try again.");
      console.error(error);
    }
    
    setLoading(false);
  };

  return (
    <>
      {/* ========================= contact Area One start =========================*/}
      <div className='contact-area'>
        <div className='container'>
          <div className='contact-inner-1'>
            <img
              className='top_image_bounce animate-img-1'
              src='assets/img/banner/2.png'
              alt='img'
            />
            <img
              className='top_image_bounce animate-img-2'
              src='assets/img/about/6.png'
              alt='img'
            />
            <div className='row'>
              <div
                className='col-lg-8'
                data-aos='fade-right'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <img className='w-100' src='assets/img/cont1.jpg' alt='img' />
              </div>
              <div
                className='col-lg-4 wow animated fadeInRight'
                data-aos='fade-left'
                data-aos-delay='200'
                data-aos-duration='1500'
              >
                <div className='section-title mb-0'>
                  <h6 className='sub-title'>GET IN TOUCH</h6>
                  <h2 className='title'>
                    Bringing Your <span>Vision</span> To Life
                  </h2>
                 
                  <form className="mt-4" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-lg-6">
          <div className="single-input-inner style-border">
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="single-input-inner style-border">
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="single-input-inner style-border">
            <input
              type="text"
              name="contact"
              placeholder="Your Phone"
              value={formData.contact}
              onChange={handleChange}
              required
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="single-input-inner style-border">
            <input
              type="text"
              name="subject"
              placeholder="Your Subject"
              value={formData.subject}
              onChange={handleChange}
              
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="single-input-inner style-border">
            <textarea
              name="message"
              placeholder="Message"
              value={formData.message}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="col-12">
          <button
            type="submit"
            className="btn btn-black mt-0 w-100 border-radius-5"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit Now"}
          </button>
        </div>
        {responseMessage && (
          <div class="p-4 mt-3 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400" role="alert">
          <span class="font-medium">{responseMessage}</span> 
        </div>
         
        )}
      </div>
    </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*========================= contact-inner One end =========================*/}
    </>
  );
};

export default ContactAreaOne;
