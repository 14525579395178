import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./pages/About";

import Contact from "./pages/Contact";
import HomeOne from "./pages/HomeOne";

import Service from "./pages/Service";
import Team from "./pages/Team";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomeOne />} />
       
        <Route exact path='/about' element={<About />} />
       
        <Route exact path='/contact' element={<Contact />} />
        <Route exact path='/service' element={<Service />} />
        <Route exact path='/projects' element={<Team />} />
        <Route exact path='/team' element={<Team />} />
      </Routes>
      <ScrollToTop smooth color='#E47D1F' />
    </BrowserRouter>
  );
}

export default App;
