import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import FooterOne from "../components/FooterOne";
import NavBar from "../components/NavBar";
import TeamAreaGroup from "../components/TeamAreaGroup";
import ProjectAreaOne from "../components/ProjectAreaOne";
import ProjectBread from "../components/ProjectBread";

const Team = () => {
  return (
    <>
      {/* Navigation Bar */}
      <NavBar />

      {/* Breadcrumb */}
      <ProjectBread title={"Projects"} />

      {/* Team Area Group */}
      <TeamAreaGroup />

    <ProjectAreaOne/>

      {/* Footer One */}
      <FooterOne />
    </>
  );
};

export default Team;
