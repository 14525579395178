import React from "react";

const WorkProcessOne = () => {
  return (
    <>
      {/*==================== Work Process One start ====================*/}
      <div className='work-process-area pd-top-120 pd-bottom-90'>
        <div className='container'>
          <div className='section-title text-center'>
            <h6 className='sub-title'>OUR SIMPLE PROCESS</h6>
            <h2 className='title'>
              World <span>Best Step</span> Our It Process
            </h2>
          </div>
          <div className='row'>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img style={{width:"50px"}} src='assets/img/icon/software.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Work 01</p>
                  <h5 className='mb-3'>Software Consultation</h5>
                  <p className='content'>
                  Understand your goals and challenges.
                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img style={{width:"50px"}} src='assets/img/icon/planning.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Work 02</p>
                  <h5 className='mb-3'>Planning</h5>
                  <p className='content'>
                  Design a roadmap with milestones.


                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img style={{width:"50px"}} src='assets/img/icon/execution.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Work 03</p>
                  <h5 className='mb-3'>Execution</h5>
                  <p className='content'>
                  Develop, test, and refine solutions.

                  </p>
                </div>
              </div>
            </div>
            <div className='col-xl-3 col-md-6'>
              <div className='single-work-process-inner'>
                <div className='thumb mb-3'>
                  <img style={{width:"50px"}} src='assets/img/icon/delivery.png' alt='img' />
                </div>
                <div className='details'>
                  <p className='process-count'>Work 04</p>
                  <h5 className='mb-3'> Delivery</h5>
                  <p className='content'>
                  Launch and provide ongoing support.


                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ==================== Work Process One end ====================*/}
    </>
  );
};

export default WorkProcessOne;
