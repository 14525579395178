let serviceList = [
  {
    title: "Mobile App Development",
    des: "Create seamless iOS and Android apps that engage users.",
    img: "assets/img/icon/app.png",
  },
  {
    title: "Web Development",
    des: "Build responsive, scalable websites that captivate and convert.",
    img: "assets/img/icon/web.png",
  },
  {
    title: "Digital Marketing",
    des: "Boost visibility with SEO, social media, and data-driven campaigns.",
    img: "assets/img/icon/marketing.png",
  },
 
  {
    title: "UI & UX Designing",
    des: "Design intuitive interfaces that delight users and boost conversions through user research, wireframing, and pixel-perfect prototypes aligned with your brand.",
    img: "assets/img/icon/uiux.png",
  },
  {
    title: "Video Editing",
    des: "Transform raw footage into polished stories that captivate audiences, enhance brand messaging, and optimize engagement across social media and marketing channels.",
    img: "assets/img/icon/editing.png",
  },
  {
    title: "SEO Expert",
    des: "Improve search rankings and organic growth with keyword research, on-page optimization, and technical SEO audits tailored to your industry.",
    img: "assets/img/icon/seo.png",
  },
  {
    title: "Graphic Designing",
    des: "Create striking visuals that strengthen brand identity, from logos and brochures to digital ads and packaging designs that stand out.",
    img: "assets/img/icon/graphic.png",
  },
  {
    title: "CRM",
    des: "Implement CRM systems to streamline customer interactions, automate workflows, and leverage data insights for improved retention and sales pipelines.",
    img: "assets/img/icon/crm.png",
  },
  {
    title: "ERP",
    des: "Integrate business operations with ERP solutions for real-time data access, inventory management, and streamlined workflows across departments.",
    img: "assets/img/icon/erp.png",
  },
  {
    title: "Cloud Services",
    des: "Migrate, manage, and optimize cloud infrastructure for scalability, security, and cost-efficiency using AWS, Azure, or Google Cloud platforms.",
    img: "assets/img/icon/cloud.png",
  },
];

export default serviceList;
