import React from "react";
import Marquee from "react-fast-marquee";
const BrandAreaOne = () => {
  return (
    <div className='about-area bg-gray pt-5 pb-5'>
      <div className='container'>
      <div className='row justify-content-center'>
          <div className='col-lg-6'>
            <div className='section-title text-center'>
            <h6 className='sub-title'>Who We Work With</h6>
<h2 className='title'>Strong <span>Partnerships</span>, Proven Results</h2>


            </div>
          </div>
        </div>
        <div className='client-slider'>
          <Marquee gradient={false}>
            <div className='thumb'>
              <img style={{width : "100px"}} src='assets/img/client/ard-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img style={{width : "100px"}} src='assets/img/client/audit-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img style={{width : "100px"}} src='assets/img/client/iv-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img style={{width : "100px"}} src='assets/img/client/brain-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img style={{width : "100px"}} src='assets/img/client/logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img style={{width : "100px"}} src='assets/img/client/rana-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img style={{width : "100px"}} src='assets/img/client/prime-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img style={{width : "100px"}} src='assets/img/client/main-logo.png' alt='img' />
            </div>
            <div className='thumb'>
              <img style={{width : "100px"}} src='assets/img/client/sams-logo.png' alt='img' />
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default BrandAreaOne;
