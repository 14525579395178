import React from "react";

const AboutAreaOne = () => {
  return (
    <>
      {/* ================== AboutAreaOne start  ==================*/}
      <div className='about-area pd-top-120'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div
                className='about-thumb-inner pe-xl-5 me-xl-5 '
                data-aos='fade-right'
                data-aos-delay='100'
                data-aos-duration='1500'
              >
                <img
                  className='animate-img-1 top_image_bounce'
                  src='assets/img/about/2.png'
                  alt='img'
                />
                <img
                  className='animate-img-2 left_image_bounce'
                  src='assets/img/about/3.png'
                  alt='img'
                />
                <img
                  className='animate-img-3 top_image_bounce'
                  src='assets/img/banner/5.svg'
                  alt='img'
                />
                <img
                  className='main-img'
                  src='assets/img/about1.jpg'
                  alt='img'
                />
              </div>
            </div>
            <div
              className='col-lg-6 '
              data-aos='fade-left'
              data-aos-delay='100'
              data-aos-duration='1500'
            >
              <div className='section-title mt-5 mt-lg-0'>
                <h6 className='sub-title'>Who We Are </h6>
                <h2 className='title'>
                Your Trusted     <span>Digital</span> Partner
                </h2>
                <p className='content mb-4 mb-xl-5'>
                At Digital Planz, we’re more than just a digital agency – we’re your partners in success. Founded with a vision to help businesses thrive in the digital era, we combine creativity, technology, and strategy to deliver solutions that drive growth.
Our team of experts is passionate about helping startups and businesses achieve their goals through innovative <span className="font-bold" style={{color : "#E58228", fontWeight: "bolder"}}>website development, app development, ERP and CRM solutions, and more.</span>  We believe in building long-term relationships with our clients, and your success is our success.
                </p>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/2.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Client-first approach</h5>
                        <p>
                        Prioritizing client needs at every step to deliver tailored and exceptional solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='single-about-inner'>
                      <div className='thumb mb-3'>
                        <img src='assets/img/icon/3.png' alt='img' />
                      </div>
                      <div className='details'>
                        <h5>Agile methodology</h5>
                        <p>
                        Embracing Agile methodology to ensure flexibility, collaboration, and rapid delivery of high-quality solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ================== AboutAreaOne End  ==================*/}
    </>
  );
};

export default AboutAreaOne;
