import React from "react";
import {
  FaArrowRight,
  FaCalendarAlt,
  FaEnvelope,
  FaFacebookF,
  FaInstagram,
  FaPhoneAlt,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Link } from "react-router-dom";

const FooterOne = () => {
  return (
    <>
      {/* ================== Footer One Start ==================*/}
      <footer className='footer-area bg-black bg-cover'>
        <div className='footer-subscribe'>
          <div className='container'>
            <div
              className='footer-subscribe-inner bg-cover'
              style={{ backgroundImage: 'url("./assets/img/bg/6.png")' }}
            >
              <div className='row'>
                <div className='col-lg-6'>
                  <h2 className='mb-lg-0 mb-3'>Subscribe To Our Newsletter</h2>
                </div>
                <div className='col-lg-6 align-self-center text-lg-end'>
                  <input type='text' placeholder='Your e-mail address' />
                  <a className='btn btn-black border-radius-0' href='#'>
                    Submit now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_about'>
                <div className='thumb'>
                  <img src='assets/img/logo.png' alt='img' />
                </div>
                <div className='details'>
                  <p>
                  Let’s build your future, one line of code at a time                  </p>
                  <p className='mt-3'>
                    <FaPhoneAlt /> +92 315 4171499
                  </p>
                
                  <ul className='social-media'>
                    <li>
                      <a href='#'>
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaInstagram />
                      </a>
                    </li>
                    <li>
                      <a href='#'>
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'>Our Service</h4>
                <ul>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> Ui Design
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> Web design
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> Digital marketing{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> Video Editing
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> App Development
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> Web Development
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget_nav_menu'>
                <h4 className='widget-title'> links</h4>
                <ul>
                  <li>
                    <Link to='/'>
                      <FaArrowRight /> Home
                    </Link>
                  </li>
                  <li>
                    <Link to='/about'>
                      <FaArrowRight /> About Us
                    </Link>
                  </li>
                  <li>
                    <Link to='/service'>
                      <FaArrowRight /> Our Services{" "}
                    </Link>
                  </li>
                  <li>
                    <Link to='/contact'>
                      <FaArrowRight /> Contact Us
                    </Link>
                  </li>
                
                 
                </ul>
              </div>
            </div>
            <div className='col-lg-3 col-md-6'>
              <div className='widget widget-recent-post'>
                <h4 className='widget-title'>Contact us</h4>
                <ul>
                  <li>
                    <div className='media'>
                      <div className='media-left'>
                        <img src='assets/img/widget/1.png' alt='blog' />
                      </div>
                      <div className='media-body align-self-center'>
                       
                        <h6 className='title mb-0'>
                          <Link to='/blog-details'>
                            Social Media For Promote Business.
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className='media'>
                      <div className='media-left'>
                        <img src='assets/img/widget/2.png' alt='blog' />
                      </div>
                      <div className='media-body align-self-center'>
                       
                        <h6 className='title mb-0'>
                          <Link to='/blog-details'>
                            Marketing For Base market watch
                          </Link>
                        </h6>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-bottom'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 align-self-center'>
                <p>© Digital Planz 2025 | All Rights Reserved</p>
              </div>
              <div className='col-md-6 text-lg-end'>
                <a href='#'>Terms &amp; Condition</a>
                <a href='#'>Privacy Policy</a>
                <a href='/contact'>Contact Us</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* ================== Footer One  end ==================*/}
    </>
  );
};

export default FooterOne;
